import { usePicture } from '@guider-global/azure-storage-hooks';
import { useProfiles, useUsers } from 'hooks';

export const useProfilePicture = () => {
  // Users
  const { users: getUsers, hasResultsUsers } = useUsers({
    getSilently: false,
  });
  const usersFound = hasResultsUsers();
  const users = getUsers();
  const user = users[0];
  const oauthPicture = usersFound ? user.picture : undefined;

  const { loading, getImage } = usePicture({});

  //  Profiles
  const {
    getProfiles,
    hasResultsProfiles,
    isLoading: getLoading,
  } = useProfiles({ getSilently: false });
  const profilesFound = hasResultsProfiles();
  const isProfileLoading = getLoading();
  const [profile] = getProfiles();
  const profilePicture = profile?.picture;
  const firstLetter = profile?.firstName.charAt(0).toUpperCase();
  const lastLetter = profile?.lastName.charAt(0).toUpperCase();

  const isLoading =
    !usersFound || !profilesFound || isProfileLoading || loading;

  const picture = !profilePicture ? oauthPicture : getImage(profilePicture);

  const initials = `${firstLetter}${lastLetter}`;

  return {
    initials,
    loading: isLoading,
    picture,
  };
};
