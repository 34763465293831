import {
  IUseRestReduxResult,
  useRestRedux,
  RestReduxHook,
} from '@guider-global/redux-axios-hooks';
import { INotification } from '@guider-global/shared-types';
import { RestRootState, RestRootStateTypes, RootState } from 'store';
import { useAuth } from '@guider-global/auth-hooks';
import modalNotificationsSlice from 'store/slices/modalNotificationsSlice';
import { useDatadogContext } from '@guider-global/datadog';

/**
 * A custom hook to handle Notifications REST API requests connected the redux store
 * @param {RestReduxHook<ResultDataType>} args are the arguments to be passed to the useRestRedux<IRelationship> hook
 * @returns ...hook - all return values via the `useRestRedux<IRelationship>()` hook
 * @returns notifications - array of notifications in the RestRootState reducer
 * @returns reqNotifications - makes requests and stores results in the notificationsReducer
 * @returns getErrorsNotifications - gets API errors from the notificationsReducer
 */
export function useModalNotifications({
  ...args
}: RestReduxHook<INotification, RestRootState, RootState>) {
  const { accessToken, getAccessToken } = useAuth({
    waitForAuthentication: true,
  });
  const { sessionId } = useDatadogContext();
  const hook: IUseRestReduxResult<INotification, RestRootStateTypes> =
    useRestRedux<INotification, RestRootState, RestRootStateTypes, RootState>({
      ...args,
      resultDataName: 'modalNotifications',
      getSilentlyUrl: '/notifications',
      actions: modalNotificationsSlice.actions,
      waitForAuthentication: true,
      accessToken,
      onExpiredAccessToken: getAccessToken,
      traceId: sessionId ?? '',
    });
  return {
    ...hook,
    getModalNotifications: hook.getResults,
    reqModalNotifications: hook.request,
    getErrorsModalNotifications: hook.getErrors,
    hasResultsModalNotifications: hook.hasResults,
    getMessageModalNotifications: hook.getMessage,
    getCodeModalNotifications: hook.getCode,
    getIsLoadingModalNotifications: hook.isLoading,
    getIsErrorModalNotifications: hook.isError,
    getIsSuccessModalNotifications: hook.isSuccess,
  };
}
